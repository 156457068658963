import { toUrl } from "../util/urls"
import { GoogleMapsLocationModel } from "./googleMapsLocationModel"
import { VideoResource } from "./videoResource"

export class EventModel {
  constructor(e) {
    this.id = e.strapiId || e.id
    this.url = e.url
    this.name = e.name || {}
    this.text = e.text || {}
    this.image_resources = e.image_resources || []
    this.video_resources = (e.video_resources || []).map(
      (v) => new VideoResource(v),
    )
    this.date = e.date
    this.event_link = e.event_link
    this.event_date = e.event_date
    this.event_end_date = e.event_end_date
    if (e.event_location && e.event_location.place_id) {
      this.event_location = new GoogleMapsLocationModel(e.event_location)
    }
  }

  getPageUrl() {
    return toUrl("/munzing-group/news-and-events/", this.url)
  }
}
