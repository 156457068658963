import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import "./icon-information.sass"

const IconInformation = ({ children, iconComponent, title }) => {
  const Icon = iconComponent

  return (
    <div className={classNames("_fp-icon-information")}>
      {iconComponent && Icon}
      <div className="_fp-icon-information__text">
        <h5 className={"_fp-heading-5"}>{title}</h5>
        {children}
      </div>
    </div>
  )
}

IconInformation.propTypes = {
  children: PropTypes.node,
  iconComponent: PropTypes.node,
  title: PropTypes.string,
}

export default IconInformation
