import { graphql } from "gatsby"
import React from "react"

import EventInformationTile from "../components/event-information-tile/event-information-tile"
import SEO from "../components/seo"
import SingleEventTile from "../components/single-event-tile/single-event-tile"
import SideNavView from "../components/views/side-nav-view/side-nav-view"
import { useTranslation } from "../i18n/translate"
import { EventModel } from "../model/eventModel"

const EventPage = ({ data, location }) => {
  const { t } = useTranslation()
  const event = new EventModel(data.strapiEvent)

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t(event.name.key)}
        descriptionTranslatedString={t(event.text.key)}
      />
      <SideNavView
        titleTranslatedString={t(event.name.key)}
        pathName={location.pathname}
      >
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-12">
            <SingleEventTile event={event} />
          </div>
          {(event.event_location || event.event_date) && (
            <div className="_fp-col-12">
              <EventInformationTile event={event} />
            </div>
          )}
        </div>
      </SideNavView>
    </>
  )
}

export default EventPage

export const query = graphql`
  query ($eventId: Int) {
    strapiEvent(strapiId: { eq: $eventId }) {
      id
      url
      strapiId
      name {
        key
      }
      text {
        key
      }
      image_resources {
        description {
          key
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      video_resources {
        description {
          key
        }
        video_resource_items {
          language
          youtubeId
        }
      }
      event_link
      event_date
      event_end_date
      event_location {
        ...GoogleMapsLocationParams
      }
    }
  }
`
