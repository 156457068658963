import { navigate } from "gatsby"
import { getI18n } from "react-i18next"

import localizeLink from "./localize-link"

export const localizedNavigate = (to) => {
  const i18n = getI18n()
  const locale = i18n.language
  return navigate(localizeLink(to, locale))
}
