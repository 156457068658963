import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps"

import { localizedNavigate } from "../../i18n/localizedNavigate"
import { withTranslation } from "../../i18n/translate"
import LocationTooltip from "../location-tooltip/location-tooltip"
import "./munzingLocationMap.sass"

const mapStyle = require("./mapStyle.json")

class MapWithAMarker extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hoveredLocation: false,
      tooltipHovered: false,
      tooltipPos: { x: 0, y: 0 },
    }
  }

  mouseoverMarker(location) {
    console.log(location)
    this.willRemoveTooltip = false

    window.setTimeout(() => {
      if (!this.state.tooltipHovered) {
        this.setState({
          tooltipHovered: true,
          hoveredLocation: location,
        })
      }
    })
  }

  mouseoutMarker() {
    this.willRemoveTooltipHover = true

    window.setTimeout(() => {
      if (this.willRemoveTooltipHover) {
        this.setState({
          tooltipHovered: false,
        })
      }
    })
  }

  componentDidMount() {
    this.mapEl = document.getElementsByClassName(
      "_fp-munzing-worldwide-google-maps-map",
    )

    this.mapEl[0].addEventListener("mousemove", (e) => {
      //If there's a timer, cancel it
      if (this.timeout) {
        window.cancelAnimationFrame(this.timeout)
      }

      //Setup the new requestAnimationFrame()
      this.timeout = window.requestAnimationFrame(() => {
        if (this.state.tooltipHovered) {
          const x = e.clientX - this.mapEl[0].getBoundingClientRect().left
          const y = e.clientY - this.mapEl[0].getBoundingClientRect().top

          this.setState({
            tooltipPos: { x, y },
          })
        }
      })
    })
  }

  render() {
    let { t, locations, useCustomMap } = this.props
    let center = { lat: 24.55, lng: 31.14 }

    if (locations.length === 1) {
      center =
        locations[0].location?.getLocation() || locations[0].getLocation()
    }

    const markerProps = locations.map((location) => {
      return { onClick: () => localizedNavigate(location.getPageUrl()) }
    })

    const mapsOptions = {}
    const markerOptions = {}

    if (useCustomMap) {
      mapsOptions.styles = mapStyle
      mapsOptions.disableDefaultUI = true
      markerOptions.defaultIcon = "/images/icons/munzing-location-marker.svg"
    }

    return (
      <>
        <GoogleMap
          defaultOptions={{
            center: center,
            zoom: useCustomMap ? 2 : 15,
            minZoom: 1,
            restriction: {
              latLngBounds: {
                north: 85,
                south: -85,
                east: 180,
                west: -180,
              },
            },
            ...mapsOptions,
          }}
        >
          {locations.map((location, index) => (
            <>
              <Marker
                key={"marker_" + index}
                position={
                  location.location?.getLocation() || location.getLocation()
                }
                {...markerOptions}
                {...markerProps[index]}
                onMouseOver={() => this.mouseoverMarker(location)}
                onMouseOut={() => this.mouseoutMarker()}
              />
            </>
          ))}
        </GoogleMap>
        {this.state.tooltipHovered && useCustomMap && (
          <LocationTooltip
            position={this.state.tooltipPos}
            label={t(this.state.hoveredLocation?.name?.key || "")}
          />
        )}
      </>
    )
  }
}

const MapVar = withTranslation()(withScriptjs(withGoogleMap(MapWithAMarker)))

const MunzingLocationMap = (props) => {
  return (
    <div className={classNames("_fp-munzing-worldwide-google-maps-map")}>
      <MapVar
        {...props}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD3utzJ6KypMIKU2XOzve3KxJ-H7Q-PkIg&v=3.exp&mapId=801f3f008cbca865"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  )
}

MunzingLocationMap.propTypes = {
  locations: PropTypes.array.isRequired,
  useCustomMap: PropTypes.bool,
  handleClick: PropTypes.array,
}

export default withTranslation()(MunzingLocationMap)
