import PropTypes from "prop-types"
import React from "react"

import "./location-tooltip.sass"

class LocationTooltip extends React.Component {
  render() {
    return (
      <div
        className={"_fp-location-tooltip"}
        style={{ left: this.props.position.x, top: this.props.position.y }}
      >
        <div className="_fp-location-tooltip__container">
          <h6>{this.props.label}</h6>
        </div>
      </div>
    )
  }
}

LocationTooltip.propTypes = {
  position: PropTypes.object,
  label: PropTypes.any,
}

export default LocationTooltip
