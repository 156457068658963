import { graphql, useStaticQuery } from "gatsby"
import * as moment from "moment"
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../i18n/translate"
import CalendarIcon from "../../images/icons/calendar.svg"
import GlobeIcon from "../../images/icons/globe.svg"
import LocationIcon from "../../images/icons/location.svg"
import { EventModel } from "../../model/eventModel"
import IconInformation from "../icon-information/icon-information"
import MunzingLocationMap from "../map/munzingLocationMap"
import Tile from "../tile/tile"
import TileContentSpacer from "../tile/tile-content-spacer/tile-content-spacer"
import "./event-information-tile.sass"

const EventInformationTile = ({ event }) => {
  const ev = new EventModel(event)

  const { t, i18n } = useTranslation()

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            author
            logoUrl
            organisationLegalName
            organisationAlternateName
            organisationName
          }
        }
      }
    `,
  )

  const imageUrls = []
  if (ev.image_resources) {
    for (let { image } of ev.image_resources) {
      imageUrls.push(image.url)
    }
  }

  return (
    <Tile
      titleTranslatedString={t(
        "MUNZING_GROUP-NEWS_AND_EVENTS-EVENT_INFORMATION",
      )}
    >
      <IconInformation
        title={t("MUNZING_GROUP-NEWS_AND_EVENTS-EVENT_DATE")}
        iconComponent={<CalendarIcon />}
      >
        <div className={"_fp-event-information__date"}>
          <time dateTime={ev.event_date}>
            {moment(ev.event_date).locale(i18n.language).format("LL")}
          </time>
          {ev.event_end_date && (
            <>
              <p>&nbsp;-&nbsp;</p>
              <time dateTime={ev.event_end_date}>
                {moment(ev.event_end_date).locale(i18n.language).format("LL")}
              </time>
            </>
          )}
        </div>
      </IconInformation>
      {event.event_link && (
        <>
          <TileContentSpacer size={"small"} />
          <IconInformation
            title={t("MUNZING_GROUP-NEWS_AND_EVENTS-EVENT_LINK")}
            iconComponent={<GlobeIcon />}
          >
            <a href={event.event_link} className={"_fp-text-link"}>
              {event.event_link}
            </a>
          </IconInformation>
        </>
      )}
      {event.event_location && (
        <>
          <TileContentSpacer size={"small"} />
          <IconInformation
            title={t("MUNZING_GROUP-NEWS_AND_EVENTS-EVENT_LOCATION")}
            iconComponent={<LocationIcon />}
          >
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href={ev.event_location.url}
              className="_fp-text-link _fp-event-information-icons__event-location__location"
            >
              {ev.event_location.getFormattedAddress()}
            </a>
          </IconInformation>
          <TileContentSpacer />
          <div className="_fp-event-information__map">
            <MunzingLocationMap
              locations={[ev.event_location]}
              useCustomMap={false}
            />
          </div>
        </>
      )}
      <script type="application/ld+json">
        {`{
                "@context": "https://schema.org",
                "@type": "Event",
                "name": "${t(ev.name.key)}",
                "startDate": "${ev.event_date}",
                ${ev.event_end_date ? `"endDate": "${ev.event_end_date}",` : ""}
                "location": {
                "@type": "Address",
                ${
                  ev.event_location
                    ? `"address": "${ev.event_location.getFormattedAddress()}",`
                    : ""
                }
            },
                ${imageUrls.length > 0 ? `"image": "${imageUrls},"` : ""}
                "description": "${t(event.text.key)}",
                "organizer": {
                "@type": "Corporation",
                "@id": "${site.siteMetadata.siteUrl}/#organisation",
            }
            }`}
      </script>
    </Tile>
  )
}

EventInformationTile.propTypes = {
  event: PropTypes.object.isRequired,
}

export default EventInformationTile
