import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { Locale } from "../../i18n/locale"
import { useTranslation } from "../../i18n/translate"
import { EventModel } from "../../model/eventModel"
import TextWithOrWithoutGallery from "../dynamic-page-components/text-with-or-without-gallery/text-with-or-without-gallery"
import {
  GalleryPictureItem,
  GalleryVideoItem,
} from "../gallery/model/gallery-item"
import Tile from "../tile/tile"

const SingleEventTile = ({ event }) => {
  const ev = event

  const { t, i18n } = useTranslation()
  const currentLocale = new Locale(i18n.language)

  const gallery = []
  const imageUrls = []

  if (ev.image_resources) {
    for (let { image, description } of ev.image_resources) {
      gallery.push(
        new GalleryPictureItem({
          gatsbyFluidImage: image.localFile.childImageSharp.gatsbyImageData,
          descriptionTranslationKey: description?.key,
        }),
      )
      imageUrls.push(image.url)
    }
  }

  if (ev.video_resources) {
    const preferredLanguage = currentLocale.getLocaleKey()

    for (const resource of ev.video_resources) {
      const galleryItem = resource.getGalleryItem(preferredLanguage)
      if (galleryItem) {
        gallery.push(galleryItem)
        imageUrls.push(galleryItem.thumbnailSrc)
      }
    }
  }

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            author
            organisationLegalName
            organisationAlternateName
            organisationName
            logoUrl
          }
        }
      }
    `,
  )

  return (
    <Tile>
      <TextWithOrWithoutGallery
        gallery={gallery}
        textTranslationKey={ev.text.key}
      />
      <script type="application/ld+json">
        {`{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {"@id": "${
                  site.siteMetadata.siteUrl + ev.getPageUrl()
                }/#webpage"},
                "isPartOf": {"@id": "${
                  site.siteMetadata.siteUrl + ev.getPageUrl()
                }/#webpage"},
                "headline": "${t(ev.name.key)}",
                ${imageUrls.length > 0 ? `"image": "${imageUrls},"` : ""}
                "datePublished": "${ev.date}",
                "dateModified": "${ev.date}",
                "publisher": {"@id": "${
                  site.siteMetadata.siteUrl
                }/#organisation"}
            }`}
      </script>
    </Tile>
  )
}

SingleEventTile.propTypes = {
  event: PropTypes.object.isRequired,
}

export default SingleEventTile
